import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const OmegaMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryOmegaMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Omega" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Omega Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/omega/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Omega Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/omega/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-omega-header.png"
                alt="Pre-Owned Certified Used Omega Watches Header"
                aria-label="Used Omega Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Omega Speedmaster</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE OMEGA SPEEDMASTER WATCHES AT
              GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED OMEGA SPEEDMASTER WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              Gray and Sons Jewelers is the #1 best used Omega watch specialist in all of South
              Florida. Anyone looking to buy a used Omega watch, sell a pre-owned Omega watch, trade
              genuine Omega watches, or repair their used Omega watch should visit{' '}
              <a href="/chat-with-rich">www.grayandsons.com</a> now to speak to a live
              representative, Viktoria and Rich, for all inquiries. Local clients around the cities
              of Miami such as Sunny Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami
              Beach, Coconut Grove, Key Biscayne, and Bal Harbour should visit our Surfside based
              store for all certified authentic pre-owned Omega watch needs.
              <br />
              <br />
              For over 42 years, Gray and Sons Jewelers has offered the best deal on all certified
              authentic and certified pre-owned Omega watches. With 6 master-trained watchmakers
              that have combined experience of over 150 years in watchmaking, Gray and Sons
              guarantee every Omega watch inventoried is certified authentic and serviced ready for
              wear with a 12-month warranty covering the movement.
              <br />
              <br />
              Many people inquire “Where can I sell my pre-owned watch near me?” or “Where can I get
              the best price for my used watch?”. Often times they get directed to our buying
              department named <a href="https://sellusyourjewelry.com/">Sell Us Your Jewelry </a>
              where clients submit images of their items for sale and we quote them online! Contact
              our expert Omega watch specialist and sell your Omega watch today!
              <br />
              <br />
              Sell Us Your Jewelry at <a href="/">Gray and Sons Jewelers</a> offers the best prices
              for pre-owned watches to clients all around the nation! We buy authentic used Omega
              watches from clients all over, even in states like New York, California, Nevada,
              Illinois, Texas, Colorado, Tennessee, and many more!
              <br />
              <br />
              As one of the leading watch brands that's been around for centuries, the iconic
              history of Omega watches began in 1848 with key-wound pocketwatches praised for their
              precision. Their first series-produced caliber “Labrador” was released in 1885 which
              led to Omega’s collaboration with Audemars Piguet in the world’s first
              minute-repeating wristwatch in 1892. Omega’s reputation blossomed worldwide in the
              1900s. In 1905 Omega was the sole timekeeper for all sporting events in Switzerland
              which led to the World Bennet Cup asking Omega to be the official timekeeper for the
              international balloon race in 1909. This race allowed Omega to become the leading
              accuracy watch brand on all six continents before reaching 1910. Came 1932, Omega
              became the official timekeeper for all sporting events at the Olympic Games; an honor
              they solely enjoy.
              <br />
              <br />
              During both World Wars, Omega watches were a large contributor to the military forces.
              Omega watches were the perfect robust and shock-proof watches to wear during combat.
              Their introduction of the first tourbillon movement in 1947 led up to the release of
              the iconic Omega Seamaster collection the following year. For the following decade
              after the Seamaster release, Omega was constantly introducing new collections. The
              Ladymatic made its debut in 1955, and in 157 Omega released the Railmaster, Seamaster
              300, and the Speedmaster, all collections still extremely desirable today.
              <br />
              <br />
              The years 1962 and 1969 were historic for the Omega brand. Astronauts Wally Schirra
              and Neil Armstrong are the ones responsible for Omega’s reputation being mostly known
              as the “first watch in space” and “the first watch on the moon”. Most watch
              aficionados will agree when saying Omega watches are the most reliable and accurate
              timepieces. Omega watches come in precious metals such as 14k and 18k yellow and rose
              and white gold as well as stainless steel. His and Hers models are made in all sizes
              for most collections. Omega watches come in all shapes and sizes from 25mm to 41mm.
              Omega is one of the few luxury watch brands whose vintage watches are as highly
              desired as their modern watches.
              <br />
              <br />
              Popular Pre-Owned Omega Watch References are: Reference 311.33.42.30.01.001 Reference
              32630405003001 Reference 231.10.43.22.01.001 Reference 210.32.42.20.06.001 Reference
              215.30.44.21.03.001 Reference 215.32.46.51.01.001 Reference 215.32.46.51.01.001
              Reference 21230445003001 Reference 212.30.36.20.51.001 Reference 23113432201001
              Reference 123.10.35.60.01.001 Reference 210.22.42.20.01.004 Reference
              123.20.35.20.02.004 Reference 326.32.40.50.06.001
              <br />
              <br />
              Clients looking to get a better deal on pre-owned Omega watches should visit Gray and
              Sons Jewelers. At G and S, we carry all kinds of used Omega watches including
              discontinued Omegas and collectible Omega watches. Vintage Omegas and Modern Omega
              watches are sold and bought weekly by Gray and Sons from clients all around the
              nation.
              <br />
              <br />
              All around the world, Omega watches are recognized and respected as fine swill luxury
              timepieces. Clients who want to add an Omega watch to their collection should only
              trust that Gray and Sons Jewelers is the best new and used Omega watch specialist.
              Here at Gray and Sons Jewelers, we have six Master-trained watchmakers with over 150
              years of combined experience who fully service every single Omega watch in our
              inventory.
              <br />
              <br />
              We provide repair services to the public as well as provide a 1-year warranty for all
              Omega watches sold at Gray and Sons. In-house we offer the following services:
              cleaning, polishing, overhaul and lubrication of the movement, battery changing,
              waterproofing, and much more! Come visit our store to repair my Omega watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/omega/">
                <button>SHOP OMEGA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h1 className="text-center py-10">IN-STOCK OMEGA WATCHES</h1>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h1 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h1>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default OmegaMain
